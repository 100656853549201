import React from 'react';
import './CustomerReview.css';

export default function CustomerReview() {
    return (
        <div className="reviewArea">
            <div className="reviewContent">
                <div className="reviewStars">
                    <div className="rHeading">Customer reviews</div>
                    <table>
                        <tbody>
                            <tr className="rTableRow">
                                <td>5 star</td>
                                <td><div className="rProgressBar"></div></td>
                                <td>0%</td>
                            </tr>
                            <tr className="rTableRow">
                                <td>4 star</td>
                                <td><div className="rProgressBar"></div></td>
                                <td>0%</td>
                            </tr>
                            <tr className="rTableRow">
                                <td>3 star</td>
                                <td><div className="rProgressBar"></div></td>
                                <td>0%</td>
                            </tr>
                            <tr className="rTableRow">
                                <td>2 star</td>
                                <td><div className="rProgressBar"></div></td>
                                <td>0%</td>
                            </tr>
                            <tr className="rTableRow">
                                <td>1 star</td>
                                <td><div className="rProgressBar"></div></td>
                                <td>0%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="topReviews">
                    <div className="trText">No Customer reviews</div>
                </div>
            </div>
            <div className="footer">
                Back to top
            </div>
        </div>
    )
}
