import React from 'react';
import './HaveaQus.css';
import search from '../../assets/search.svg';

export default function HaveaQus() {
    return (
        <div className="haveAqusArea">
            <div className="haqHeading">Have a question?</div>
            <div className="haqText">Find answers in candidate info, Q&As, reviews</div>
            <div className="haqSearch">
                <img alt="searchImg" className="haqSearchIcon" src={search}/>
                <input className="haqSearchText" type="search" placeholder="Type your question or keyword"></input>
            </div>
        </div>
    )
}
