import React from 'react';
import './Ratings.css';
import star from '../../assets/star.svg';

export default function Ratings() {
    return (
        <div className="ratingsArea">
            <div className="ratingHead">
                <img alt="starImage" className="mrStarImg" src={star}/>
                4 out of 5
            </div>
            <div className="ratingText">5 global ratings</div>
            <div className="ratingTable">
                <table className="mrTableContent">
                    <tbody>
                        <tr className="mrTableRow">
                            <td>4.5 star</td>
                            <td className="mrpHolder">
                                <div className="mrProgressBar"></div>
                                <div className="mrProgressBarA"></div>
                            </td>
                            <td>Python</td>
                        </tr>
                        <tr className="mrTableRow">
                            <td>4.5 star</td>
                            <td className="mrpHolder">
                                <div className="mrProgressBar"></div>
                                <div className="mrProgressBarB"></div>
                            </td>
                            <td>Django</td>
                        </tr>
                        <tr className="mrTableRow">
                            <td>4.5 star</td>
                            <td className="mrpHolder">
                                <div className="mrProgressBar"></div>
                                <div className="mrProgressBarC"></div>
                            </td>
                            <td>HTML/CSS/Material-UI</td>
                        </tr>
                        <tr className="mrTableRow">
                            <td>3.5 star</td>
                            <td className="mrpHolder">
                                <div className="mrProgressBar"></div>
                                <div className="mrProgressBarD"></div>
                            </td>
                            <td>React.js</td>
                        </tr>
                        <tr className="mrTableRow">
                            <td>3.5 star</td>
                            <td className="mrpHolder">
                                <div className="mrProgressBar"></div>
                                <div className="mrProgressBarE"></div>
                            </td>
                            <td>Oracle Sql</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
