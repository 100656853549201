import React from 'react';
import './CentreArea.css';
import mine from '../../assets/IMG_1487.jpg';
import skills from '../../assets/skills.jpg';
import star from '../../assets/star.svg';
import offer from '../../assets/discount.svg';
import git from '../../assets/github.svg';
import hackerrank from '../../assets/HackerRank.svg';
import gmail from '../../assets/gmail.svg';
import map from '../../assets/map.svg';
import lock from '../../assets/padlock.svg';
import linkedin from '../../assets/linkedin.svg';
import coordinator from '../../assets/coordinator.svg';
import goal from '../../assets/goal.svg';
import shakehand from '../../assets/shakehand.svg';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Ratings from './Ratings.js';

const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));

export default function CentreArea() {
    const [selectedImg, setSelectedImg] = React.useState(mine)
    const handleclick = (event) => {
        setSelectedImg(event.target.src)
    };
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div className="mainBody">
            <div className="productImages">
                <div className="small-img">
                    <div><img alt="mine" src={mine} onClick={handleclick}/></div>
                    <div><img alt="skills" src={skills} onClick={handleclick}/></div>
                </div>
                <div className="big-img">
                    <img alt="selectedImg" src={selectedImg} />
                </div>
            </div>
            <div className="productDescription">
                <span className="productName">Praveen Kumar Mamani</span>
                <span className="productName">Senior Software Engineer</span>
                <div className="ratings" 
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <img alt="starImg" className="star" src={star}/>
                    <KeyboardArrowDownIcon className="downArrow"/>
                    <span>5 ratings</span>
                </div>
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Ratings />   
                </Popover>
                <div className="line"></div>
                <div className="yearsTable">
                    <table>
                        <tbody>
                            <tr>
                                <td className="tableHead">Total Work Experience : </td>
                                <td className="tableValue1">6 Years</td>
                            </tr>
                            <tr>
                                <td className="tableHead">Technical Experience : </td>
                                <td className="tableValue">5 Years</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="available">Available in: <span className="availableDays">90 Days</span></div>
                </div>
                <div className="highlightsTable">
                    <table className="htTable">
                        <tbody>
                            <tr>
                                <td className="htHead">
                                    <img alt="offerImage" className="offerImg" src={offer}/>
                                    <span className="keyPoints">Key Points &nbsp;</span>
                                    <span>in my career</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="htHead">
                                    <p>
                                        <span className="keyPoints">Workflow Management Application &nbsp;</span>
                                        Designed and developed a workflow monitoring and processing application
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="htHead">
                                    <p>
                                        <span className="keyPoints">Flat file conversion Algorithm &nbsp;</span>
                                        Created an algorthm to convert flat file to materialized JSON file with 87% increased efficiency from the existing algorithm
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="htHead">
                                    <p>
                                        <span className="keyPoints">Credential Management Application &nbsp;</span>
                                        Developed a web application which maintains 50k - 60k client login credentials by changing passwords periodically.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="htHead">
                                    <p>
                                        <span className="keyPoints">Android App &nbsp;</span>
                                        Created social networking app for Lions club members.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="htHead">
                                    <p>
                                        <span className="keyPoints">E commerce website &nbsp;</span>
                                        Developed two ecommerce websites <a className="keyHighWebsites" href="http://www.urtailor.in/">urtaior</a>, <a className="keyHighWebsites" href="http://urbroker.herokuapp.com/">urbroker</a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="specialIcons">
                    <div className="iconA">
                        <img alt="goalImg" src={goal}/>
                        <div>Challenge <br />welcomed</div>
                    </div>
                    <div className="iconA">
                        <img alt="coordinatorImg" src={coordinator}/>
                        <div>Mastered Worklife<br /> balance</div>
                    </div>
                    <div className="iconA">
                        <img alt="shakehandImg" src={shakehand}/>
                        <div>Teamwork <br />Delivered</div>
                    </div>
                </div>
                <div className="line"></div>
                <div className="inStock">In Stock.</div>
                <div className="soldBy">Fulfilled by <span>Praveen kumar.</span></div>
                <div className="aboutThis">About this Candidate</div>
                <div className="extraPoints">
                    <ul>
                        <li>
                            Born and brought up in Chennai, Tamil Nadu, India.
                        </li>
                        <li>
                            Graduated B.E, E.C.E in 2014.
                        </li>
                        <li>
                            Speaks English, Tamil, Hindi, French.
                        </li>
                        <li>
                            Received scholarship from Google for Android development.
                        </li>
                        <li>
                            Received scholarship from Facebook for pytorch.
                        </li>
                    </ul>
                </div>
                <div className="reportInfo">
                    <ModeCommentOutlinedIcon className="infoIcon"/>
                    <span>Report incorrect information.</span>
                </div>
            </div>
            <div className="productBuy">
                <div className="links">
                    <div className="linkName">Links</div>
                    <a href="mailto: praveenkumarkvcet@gmail.com"><img alt="gmailImg" className="linkIcon" src={gmail}/></a>
                    <a href="https://www.linkedin.com/in/praveen-kumar-mamani/"><img alt="linkedinImg" className="linkIcon" src={linkedin}/></a>
                    <a href="https://www.hackerrank.com/praveenkmamani?hr_r=1"><img alt="hackerrankImg" className="linkIcon" src={hackerrank}/></a>
                    <a href="https://github.com/praveenkmamani"><img alt="gitImg" className="linkIcon" src={git}/></a>
                </div>
                <div className="buyBox">
                    <div className="quantityArea">
                        <label for="quantity">Quantity:</label>
                        <select id="quantity">
                            <option value="one">1</option>
                        </select>
                    </div>
                    <div className="buttons">
                        <input className="addBtn" type="button" value="Add to Cart"></input>
                        <input className="buyBtn" type="button" value="Buy Now"></input>
                    </div>
                    <div className="secureTrans">
                        <img alt="lockImg" className="lock" src={lock}/>
                        <div className="stText">Secure transactions</div>
                    </div>
                    <div className="giftOptn">
                        <input className="giftBtn" type="checkbox"></input>
                        <label>Add gift options</label>
                    </div>
                    <div className="deliverToPraveen">
                        <img alt="deliverImg" className="deliverToPraveenImg" src={map}/>
                        <div className="deliverToPraveenTxt">Deliver to Praveen - Chennai 600032</div>
                    </div>
                    <div className="line"></div>
                    <div className="wishlist">
                        <input className="wishlistBtn" type="button" value="Add to Wish List"></input>
                        <input className="wishlistBtnArrow" type="button" value="v"></input>
                    </div>
                </div>
            </div>
            <div className="line"></div>
        </div>
    )
}
