import React from 'react';
import './FrequentlyBought.css';
import mine from '../../assets/IMG_1487.jpg';
import blocks from '../../assets/blocks.svg';
import animation from '../../assets/animation.svg';

export default function FrequentlyBought() {
    return (
        <div className="freqBoughtArea">
            <div className="fbHeading">Frequently bought together</div>
            <div className="fbImgaBtn">
                <div className="fbImages">
                    <img alt="myImg" className="fbImg" src={mine}/>
                    <div className="plus">+</div>
                    <img alt="blockImg" className="fbImg" src={blocks}/>
                    <div className="plus">+</div>
                    <img alt="animationImg" className="fbImg" src={animation}/>
                </div>
                <div><input className="fbAddtoCart" type="button" value="Add all to Cart"></input></div>
            </div>
            <div className="fbItemDetail">
                <div className="fbItemDetailitem">
                    <input className="fbCheckbox" type="checkbox" checked></input>
                    <label className="fbcbTextA" ><span className="fbcbTextBold">This item: </span>Praveen kumar (Senior Software Engineer)</label>
                </div>
                <div className="fbItemDetailitem">
                    <input className="fbCheckbox" type="checkbox" checked></input>
                    <label className="fbcbText">Lego Master Builder skill</label>
                </div>
                <div className="fbItemDetailitem">
                    <input className="fbCheckbox" type="checkbox" checked></input>
                    <label className="fbcbText">Stop motion animation skill</label>
                </div>
            </div>
        </div>
    )
}
