import './App.css';
import Nav from './components/Navbar/Navbar.js';
import Body from './components/MainPage/CentreArea.js';
import FrequentlyBought from './components/MainPage/FrequentlyBought.js';
import HaveaQus from './components/MainPage/HaveaQus.js';
import Experience from './components/MainPage/Experience.js';
import CusReview from './components/MainPage/CustomerReview';

function App() {
  return (
	<div className="App">
		<Nav />
		<Body />
		<FrequentlyBought />
		<HaveaQus />
		<Experience />
		<CusReview />
	</div>
  );
}

export default App;
