import React from 'react';
import './Experience.css';

export default function Experience() {
    return (
        <div className="experienceArea">
            <div className="eaHeading">Experience</div>
            <div className="eaOrgs">
                <div className="eaOrg">
                    <div className="eaOrgName">Optum Insight</div>
                    <div className="eaOrgExperience">
                        <table className="eaoeTable">
                            <tbody>
                                <tr>
                                    <th className="eTableHead">Job Profile:</th>
                                    <td className="eTableText">Senior Software Engineer</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Duration:</th>
                                    <td className="eTableText">2019-07 – Current</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Language Used:</th>
                                    <td className="eTableText">Python, Django, React.js, Material-UI, Oracle SQL, AWS(Step functions)</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Environment:</th>
                                    <td className="eTableText">Linux and Windows</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Code Versioning:</th>
                                    <td className="eTableText">Git and svn</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Project:</th>
                                    <td className="eTableText">Designed and Developing a web application for the operations team and SLT’s to monitor the application pipeline of the entire process, debug build errors, handle and resume build failures. Generates various reports and statistics. JIRA ticket integration.</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Additional Tasks</th>
                                    <td className="eTableText">Sprint Planning, Code review, Mentoring</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="eaOrg">
                    <div className="eaOrgName">Episource</div>
                    <div className="eaOrgExperience">
                        <table className="eaoeTable">
                            <tbody>
                                <tr>
                                    <th className="eTableHead">Job Profile:</th>
                                    <td className="eTableText">Software Engineer</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Duration:</th>
                                    <td className="eTableText">2019-01 – 2019-07</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Language Used:</th>
                                    <td className="eTableText">Python, AWS(Lambda)</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Environment:</th>
                                    <td className="eTableText">Windows</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Code Versioning:</th>
                                    <td className="eTableText">Git</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Project:</th>
                                    <td className="eTableText">Developed an aggressive algorithmic module to convert all types of flat files to a Materialized JSON file with time and memory efficient. Used complex algorithms to convert flat file to JSON structure. Achieved 60 GB file conversion in 240 seconds. </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="eaOrg">
                    <div className="eaOrgName">AthenaHealth</div>
                    <div className="eaOrgExperience">
                        <table className="eaoeTable">
                            <tbody>
                                <tr>
                                    <th className="eTableHead">Job Profile:</th>
                                    <td className="eTableText">Python Developer</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Duration:</th>
                                    <td className="eTableText">2015-12 – 2019-01</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Language Used:</th>
                                    <td className="eTableText">Python, Django, HTML, CSS, js, selenium, pandas, VBA</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Environment:</th>
                                    <td className="eTableText">Windows</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Code Versioning:</th>
                                    <td className="eTableText">Git</td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Project:</th>
                                    <td className="eTableText">Developed a web application which handles 50,000-60,000 login credentials and maintains it active by changing passwords periodically by entering into the various portals and update it in our database. </td>
                                </tr>
                                <tr>
                                    <th className="eTableHead">Project:</th>
                                    <td className="eTableText">Developed a web application to monitor and control the online windows servers. An interactive web application to view the status of the windows servers and to control online, provide reports and status live via dashboard on home page and instant error notifications via slack.  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
