import React from 'react';
import './Navbar.css';
import smile from '../../assets/amazon.svg';
import map from '../../assets/placeholder.svg';
import search from '../../assets/search.svg';
import flag from '../../assets/india.svg';
import shoppingcart from '../../assets/cart.svg';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

export default function Navbar() {
    return (
        <div>
            <div className="navbar">
                <div className="logo">
                    <div className="logoName">
                        praveen<span>.in</span>
                    </div>
                    <img alt="smileImage" src={smile}/>
                </div>
                <div className="deliver">
                    <div className="map">
                        <img alt="mapImage" className="mapIcon" src={map}/>
                    </div>
                    <div className="deliverDetail">
                        <div className="deliverTo">
                            Deliver to Praveen
                        </div>
                        <div className="deliverArea">
                            Chennai 600032
                        </div>
                    </div>
                </div>
                <div className="search">
                    <div className="recruitment">
                        <span>Recruitment</span>
                        <div className="triangle"></div>
                    </div>
                    <input className="inputholder" placeholder="Praveen Kumar"></input>
                    <div className="searchIcon">
                        <img alt="seachImage" className="seachiconimg" src={search}/>
                    </div>
                </div>
                <div className="flag">
                    <img alt="flagImage" className="flagSvg" src={flag} />
                    <div className="FlagTriangle"></div>
                </div>
                <div className="hello">
                    <span className="helloPra">Hello, Praveen</span>
                    <div className="accounts">
                        <span className="accAlist">Accounts & Lists</span>
                        <div className="FlagTriangle"></div>
                    </div>			
                </div>
                <div className="returnSec">
                    <span className="returns">Returns</span>
                    <span className="orders">& Orders</span>
                </div>
                <div className="cart">
                    <img alt="cartImage" className="cartImg" src={shoppingcart}/>
                    <span className="accAlist">Cart</span>
                </div>
            </div>
            <div className="secondNav">
                <div className="hamburger">
                    <div className="ham"></div>
                    <div className="ham"></div>
                    <div className="ham"></div>
                </div>
                <div className="categories">
                    <div>Python</div>
                    <div>Django</div>
                    <div>React.js</div>
                    <div>RESTful API</div>
                    <div>HTML</div>
                    <div>CSS</div>
                    <div>Advanced Excel</div>
                    <div>PPT Animation</div>
                    <div>Linux</div>
                    <div>Android</div>
                </div>
                <div className="resumeDownload">
                    <div class="triangle-left"></div>
                    <span className="myskills">My Skills</span>
                </div>
            </div>
            <div className="navigationTree">
                <a href="https://en.wikipedia.org/wiki/Chennai">Born and brought up in chennai</a>
                <KeyboardArrowRightIcon className="rightArrow"/>
                <a href="https://kveg.in/">B.E(ECE) graduate from KVCET</a>
                <KeyboardArrowRightIcon className="rightArrow"/>
                <a href="https://in.topresume.com/career-advice/senior-software-engineer-job-description">Senior Software Engineer</a>
            </div>
        </div>
    )
}
